
import { defineComponent, ref, Ref } from 'vue';
import {
    listPagination, ListHeaderItem, RequestData
} from '@/components/common/list';
import firmwareModel, { FirmwareForm } from '@/components/view/installer/community/firmware-dialog';
import { isCommunity, isOffice, isInstaller } from '@/methods/role';

const headers: Array<ListHeaderItem> = [{
    name: 'Version',
    label: WordList.TabelUpdateBoxVersion
}, {
    name: 'Model',
    label: WordList.TabelVersionModel
}, {
    name: 'Log',
    label: WordList.TabelVersionLog,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];
type formType = 'Version' | 'Model' | 'Log';

export default defineComponent({
    components: {
        listPagination,
        firmwareModel
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${isCommunity() ? 'community' : 'office'}/version/getVersionList`,
            param: {}
        });

        const isShowFirmwareModel = ref(false);
        const initForm: Ref<FirmwareForm> = ref({
            Log: '',
            Version: '',
            Model: ''
        });

        // 查看固件详情
        const info = (detail: FirmwareForm) => {
            Object.keys(initForm.value).forEach((item) => {
                initForm.value[item as formType] = detail[item as formType];
            });
            isShowFirmwareModel.value = true;
        };

        return {
            info,
            headers,
            updateToList,
            listRequestData,
            isShowFirmwareModel,
            initForm
        };
    }
});
